import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import { NavigationLink } from '../components/link';
import { findNamedBacgroundAsset, TeaserSection, BaseContentSection, PreviewSection, EventPreviewSection, ContactsSection, EventPreviewSectionProps, GetAssetImageAnticolor, SimpleButton } from '../components/section';
import { SimpleParagraphContent } from '../templates/template-base';
import { getPageItemGroupItems, getPageItemGroup } from '../utils/gatsby-types';
import { PAGES_INFO } from '../utils/pages-info';
import {ItemGroup, ItemGroups} from '../templates/item-group'
import {PageSections} from '../templates/page-sections'
import createTranslate from '../utils/translator-provider';

export default ({ data }) => {
  const translate = createTranslate(data?.page?.translations);
  const contentBackgroundAsset = findNamedBacgroundAsset("Background", data?.page?.sectionBackgrounds);

  const buttonColor = GetAssetImageAnticolor(contentBackgroundAsset?.asset);

  const rightItemGroups = [
    getPageItemGroup(data.page, "Contacts")
  ].filter(x => x)

  return (
    <Layout
    title={data.page.title}
    description={data.page.seoDescription}
    path={PAGES_INFO.suomiAreenaPoriVastuullisuusPage.path}
    fixedBackground={contentBackgroundAsset?.asset}
>
    <TeaserSection
        backgroundAssets={data?.page?.sectionBackgrounds}
        sectionName={'Teaser'}
        title={translate('suomiAreenaPoriVastuullisuusPage.teaserSection.title')}
        body={translate('suomiAreenaPoriVastuullisuusPage.teaserSection.body')}
    />

    <BaseContentSection>
        {/** Left column 2/3 size */}
        <div className="col-span-1 lg:col-span-2 flex flex-col gap-10 px-12 md:px-0">
            {/** Content sections */}
            {data.page.pageSections ? <PageSections pageSections={data.page.pageSections}/> : null }
        </div>

        {/** spacer */}
        <div className="lg:hidden h-12"/>

        {/** Right column 1/3 size */}
        <div className="flex flex-col gap-10 px-12 md:px-0">
          <ItemGroups itemGroups={rightItemGroups} />
        </div>
    </BaseContentSection>
</Layout>
  );
};


export const query = graphql`
query {
  page: contentfulPage(name: { eq: "SuomiAreena Pori Vastuullisuus" }) {
    title
    seoDescription
    sectionBackgrounds {
      name
      asset {
        title
        file {
          contentType
          url
        }
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 3000) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        fluid(maxWidth: 3000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      brightness
      useShadowForText
      objectPositionVertical
      objectPositionHorizontal
    }
    pageSections {
      title
      sectionParagraphs {
        ... on ContentfulCampaignContentParagraph {
          name
          columnsToTake
          content {
            json
          }
        }
        ... on ContentfulItemGroup {
          id
          header
          title
          items {
            ... on ContentfulPreviewContent {
          title
          predescription {

            ... on contentfulPreviewContentPredescriptionTextNode {
                  predescription
                }
              }
              image  {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 800) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                    fluid(maxWidth: 800) {
                      ...GatsbyContentfulFluid_withWebp_noBase64
                    }
              }
              linkText
              link
            }

            ... on ContentfulPartner {
                  name
                  description
                  image {
                      file {
                          contentType
                          url
                      }
                      localFile {
                          publicURL
                          childImageSharp {
                          fluid(maxWidth: 3000) {
                              ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                          }
                      }
                      fluid(maxWidth: 3000) {
                          ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                  }
              }
          }
        }
      }
    }

    itemGroups {
      id
      header
      title
      items {
        ... on ContentfulCampaignContentParagraph {
              columnsToTake
              content {
                json
              }
            }

            ... on ContentfulContact {
                    firstName
                    lastName
                    title
                    email
                    phoneNumber
            }

        ... on ContentfulPreviewContent {
          title
          predescription {
            ... on contentfulPreviewContentPredescriptionTextNode {
              predescription
            }
          }
          image  {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
                fluid(maxWidth: 800) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
          }
          linkText
          link
        }

        ... on ContentfulItemGroup {
          id
          header
          title
          items {
            ... on ContentfulPreviewContent {
              title
              predescription {
                ... on contentfulPreviewContentPredescriptionTextNode {
                  predescription
                }
              }
              image  {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 800) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                    fluid(maxWidth: 800) {
                      ...GatsbyContentfulFluid_withWebp_noBase64
                    }
              }
              linkText
              link
            }

            ... on ContentfulContact {
                    firstName
                    lastName
                    title
                    email
                    phoneNumber
            }

            ... on ContentfulArticle {
              tag
              title
              previewImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
                fluid(maxWidth: 800) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              landingPagePreviewText {
                landingPagePreviewText
              }
              slug
              orderInLandingPage
            }

            ... on ContentfulCampaignContentParagraph {
              columnsToTake
              content {
                json
              }
            }

            ... on ContentfulLiveEvent {
                date
                location
                city
                icon{
                  file {
                    contentType
                    url
                  }
                  localFile {
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 3000) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                  fluid(maxWidth: 3000) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
                background {
                  title
                  file {
                    contentType
                    url
                  }
                  localFile {
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 3000) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                  fluid(maxWidth: 3000) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
                linkText
                linkUrl
            }
            ... on ContentfulPartner {
                  name
                  description
                  image {
                      file {
                          contentType
                          url
                      }
                      localFile {
                          publicURL
                          childImageSharp {
                          fluid(maxWidth: 3000) {
                              ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                          }
                      }
                      fluid(maxWidth: 3000) {
                          ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                  }
            }
            ... on ContentfulCampaign {
                  id
                  header
                  previewText {
                    previewText
                  }
                  previewLinkText
                  slug
                  internal {
                    type
                  }
                  previewBackgroundAsset {
                    asset {
                      file {
                        contentType
                        url
                      }
                      localFile {
                        publicURL
                        childImageSharp {
                          fluid(maxWidth: 3000) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                      fluid(maxWidth: 3000) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                    }
                    brightness
                    useShadowForText
                    objectPositionVertical
                    objectPositionHorizontal
                }
            }
            ... on ContentfulItemGroup {
                  id
                  header
                  title
                  items {
                    ... on ContentfulArticle {
                      tag
                      title
                      previewImage {
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 800) {
                              ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                          }
                        }
                        fluid(maxWidth: 800) {
                          ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                      }
                      landingPagePreviewText {
                        landingPagePreviewText
                      }
                      slug
                      orderInLandingPage
                    }

                    ... on ContentfulCampaignContentParagraph {
                      columnsToTake
                      content {
                        json
                      }
                     }

                    ... on ContentfulContact {
                            firstName
                            lastName
                            title
                            email
                            phoneNumber
                    }

                    ... on ContentfulLiveEvent {
                      date
                      location
                      city
                      icon{
                        file {
                          contentType
                          url
                        }
                        localFile {
                          publicURL
                          childImageSharp {
                            fluid(maxWidth: 3000) {
                              ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                          }
                        }
                        fluid(maxWidth: 3000) {
                          ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                      }
                      background {
                        title
                        file {
                          contentType
                          url
                        }
                        localFile {
                          publicURL
                          childImageSharp {
                            fluid(maxWidth: 3000) {
                              ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                          }
                        }
                        fluid(maxWidth: 3000) {
                          ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                      }
                      linkText
                      linkUrl
                    }

                    ... on ContentfulPartner {
                        name
                        description
                        image {
                            file {
                                contentType
                                url
                            }
                            localFile {
                                publicURL
                                childImageSharp {
                                fluid(maxWidth: 3000) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                                }
                            }
                            fluid(maxWidth: 3000) {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                        }
                    }

                    ... on ContentfulCampaign {
                        id
                        header
                        previewText {
                          previewText
                        }
                        previewLinkText
                        slug
                        internal {
                          type
                        }
                        previewBackgroundAsset {
                          asset {
                            file {
                              contentType
                              url
                            }
                            localFile {
                              publicURL
                              childImageSharp {
                                fluid(maxWidth: 3000) {
                                  ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                              }
                            }
                            fluid(maxWidth: 3000) {
                              ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                          }
                          brightness
                          useShadowForText
                          objectPositionVertical
                          objectPositionHorizontal
                      }
                    }
                  }
                }
              }
            }
          }
        }
    translations {
      key
      value {
        value
      }
    }
  }
}
`;
